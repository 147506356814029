import React from "react";
import data from "./data";
import Card from "./Card";
import { Container, Wrapper } from "./styles";

export const DigitalProcess = () => {
  return (
    <Container>
      <Wrapper>
        {data.map((item) => (
          <Card key={item.id} {...item} />
        ))}
      </Wrapper>
    </Container>
  );
};
