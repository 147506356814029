import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import { IoMdArrowRoundForward } from "react-icons/io";
import { breakpoints, colors } from "../../styles/variables";

export const Wrapper = styled.div`
  padding: 5rem 0 3rem;
  max-width: 1400px;
  margin: 0 auto;
  @media (min-width: ${breakpoints.sm}) {
    padding: 10rem 0 5rem;
  }
`;

export const StyledImg = styled(Img)`
  min-width: 50%;
  max-height: 300px;
  transition: min-width 0.5s;
`;

export const SlideDrop = styled.div`
  min-width: 50%;
  transition: min-width 0.5s
  background-color: ${colors.primaryDark};
  flex-basis: 50%;
  padding: 1rem;
  margin: auto;
  @media (min-width: ${breakpoints.sm}) {
    padding: 2rem 7rem;
  }
`;

export const ContentsContainer = styled.div`
  min-width: 50%;
  transition: min-width 0.5s
  background-color: ${colors.primaryDark};
  flex-basis: 50%;
  display: flex;
  align-items: middle;
  flex-direction: column;
  justify-content: center;
  @media (min-width: ${breakpoints.sm}) {
    align-items: baseline;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  text-decoration: none;
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  &:hover ${StyledImg} {
    min-width: 50%;
  }
  &:hover ${ContentsContainer} {
    min-width: 50%;
  }
  @media (min-width: ${breakpoints.sm}) {
    &:hover ${StyledImg} {
      min-width: 24%;
    }
    &:hover ${ContentsContainer} {
      min-width: 76%;
    }
  }
`;
export const SlideTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${colors.secondary};
  opacity: 1;
  transition: opacity 0.5s;
  text-align: center;
  text-transform: uppercase;
  @media (min-width: ${breakpoints.sm}) {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  margin: 1.5rem 0;
  color: ${colors.darkGray};
  font-family: "Red Hat Display", sans-serif;
  display: none;
  @media (min-width: ${breakpoints.sm}) {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const LearnMoreWrapper = styled.div`
  display: none;
  @media (min-width: ${breakpoints.sm}) {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
`;
export const LearnMore = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: ${colors.secondary};
  padding: 0 0.4rem 0 0rem;
  margin: 0;
  line-height: 1.2rem;
`;

export const Arrow = styled(IoMdArrowRoundForward)`
  font-size: 1rem;
  color: ${colors.secondary};
  line-height: 1.2rem;
`;
