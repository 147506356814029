import React, { ReactComponentElement, ReactNode } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";
import { ModalPopup, PopupContents } from "../PopupContents";
import usePopup from "../../hooks/usePopup";
import {
  Wrapper,
  StyledImg,
  SlideWrapper,
  TitleContainer,
  Title,
  Details,
  StyledButton,
  SecondTitleContainer,
  SecondTitle,
} from "./styles";

const slideSettings = {
  arrows: false,
  pauseOnHover: false,
  dots: false,
  fade: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
};

const HeroVideo = () => {
  const image = useStaticQuery(graphql`
    fragment ImageFragment on File {
      childImageSharp {
        fluid(maxWidth: 4500, maxHeight: 2000, grayscale: false, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      thumbnail_1: file(relativePath: { eq: "hero/girl-g56538acfd_1920.jpg" }) {
        ...ImageFragment
      }
    }
  `);

  return (
    <Wrapper>
      <SlideWrapper>
        <Slider {...slideSettings}>
          <StyledImg
            fluid={image.thumbnail_1.childImageSharp.fluid}
            alt="Gippsland orthodontics"
          />
        </Slider>
      </SlideWrapper>
      <TitleContainer>
        <Title>Our brand new state-of-the-art practice is now open.</Title>
        <Details>
          Please contact us to start your personalised orthodontic journey to
          that new smile.
        </Details>
        <ModalPopup
          trigger={<StyledButton>Contact Us Today!</StyledButton>}
          modal
          nested
        >
          {(close) => <PopupContents close={close} />}
        </ModalPopup>
      </TitleContainer>
      <SecondTitleContainer>
        <SecondTitle>
          Dr Adam Wallace has been creating beautiful smiles for 18 years. He
          looks forward to sharing your orthodontic journey.
        </SecondTitle>
      </SecondTitleContainer>
    </Wrapper>
  );
};

export default HeroVideo;
