import React from "react";
import {
  StyledLink,
  SlideDrop,
  SlideTitle,
  Arrow,
  Description,
  LearnMore,
  LearnMoreWrapper,
  StyledImg,
  ContentsContainer,
} from "./styles";

const Slide = ({ id, image, title, description }) => (
  <StyledLink to={`/what-we-do/${id}`}>
    <StyledImg fluid={image} />
    <SlideDrop>
      <ContentsContainer>
        <SlideTitle>{title}</SlideTitle>
        <Description>{description}</Description>
        <LearnMoreWrapper>
          <LearnMore>Learn More</LearnMore>
          <Arrow></Arrow>
        </LearnMoreWrapper>
      </ContentsContainer>
    </SlideDrop>
  </StyledLink>
);

export default Slide;
