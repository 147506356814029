import React from "react";
import useData from "./useData";
import { Wrapper } from "./styles";
import Slide from "./Slide";

const LineupCards = () => {
  const data = useData();

  return (
    <Wrapper>
      {data.map((item) => (
        <Slide key={item.id} {...item} />
      ))}
    </Wrapper>
  );
};

export default LineupCards;
