import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import usePopup from "../hooks/usePopup";
import MailChimpContainer from "./mailchimp-form";

const XButton = styled.div`
  float: right;
  color: #aaaaaa;
  float: right;
  font-size: 2rem;
  font-weight: bold;
  padding-right: 1rem;

  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;
const ModalContainer = styled.div`
  width: 100%;
`;
const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  border-top: solid 1px #eee;
`;
const ModalButton = styled.a`
  display: inline-block;
  margin: 1rem 1rem 0 0;
  max-width: 10rem;
  text-align: center;
  color: #fff;
  border: solid 1px ${colors.dark};
  background-color: ${colors.dark};
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0.5rem 1.5rem;
  font-size: 0.8rem;
  border-radius: 2px;
  &:hover {
    color: #fff;
    background-color: ${colors.darkGray};
    border-color: ${colors.darkGray};
  }
`;
const ModalHeader = styled.div`
  padding: 1rem 1rem 0 1rem;
  font-size: 1.2rem;
  font-weight: 500;
`;
const ModalContent = styled.div`
  padding: 1rem 1rem 2rem 1rem;
  font-family: "Red Hat Display", sans-serif;
`;

export const ModalPopup = styled(Popup)`
  &-content {
    width: 85%;
    @media (min-width: ${breakpoints.sm}) {
      width: 50%;
    }
    @media (min-width: ${breakpoints.lg}) {
      width: 30%;
    }
  }
`;

export const PopupContents = ({ close }) => {
  return (
    <ModalContainer>
      <XButton onClick={close}>&times;</XButton>
      <ModalContent>
        <MailChimpContainer close={close} />
      </ModalContent>
      {/* <ModalActions>
        <ModalButton onClick={close}>Close</ModalButton>
      </ModalActions> */}
    </ModalContainer>
  );
};
