import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

export const Wrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

export const ContentsWrapper = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.h1`
  padding: 3rem 0 0;
  text-align: center;
  color: ${colors.secondary};
`;

export const Contents = styled.p`
  width: 100%;
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1.375rem;
  color: ${colors.secondary};
  margin-bottom: 0.5rem;
  @media (min-width: ${breakpoints.md}) {
    font-size: 1.75rem;
  }
`;
