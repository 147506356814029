type Data = Array<{
  id: string;
  svg: string;
  title: string;
  contents: string;
  link: string;
  internalRoute: boolean;
}>;

const data: Data = [
  {
    id: "newPatient",
    svg: "TeethSVG",
    title: "Start the process to that perfect smile",
    contents: "Contact us to start the process",
    link: "/contact",
    internalRoute: true,
  },
  // {
  //   id: "infotUs",
  //   svg: "InfoSVG",
  //   title: "Patient Information",
  //   contents: "Patient information",
  //   link: "/contact",
  //   internalRoute: true,
  // },
  {
    id: "referralForm",
    svg: "LetterSVG",
    title: "Referral Form",
    contents: "Paperless online referral form for other doctors",
    link: "/referral",
    internalRoute: true,
  },
];

export default data;
