import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Wrapper, ContentsWrapper, Title, Contents } from "./styles";

const SubTitle = () => {
  const image = useStaticQuery(graphql`
    fragment ImageSubTitle on File {
      childImageSharp {
        fluid(maxWidth: 1900, maxHeight: 850, grayscale: false, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      thumbnail_1: file(relativePath: { eq: "001_Gippsland.png" }) {
        ...ImageSubTitle
      }
    }
  `);
  return (
    <Wrapper>
      <Title>Welcome!</Title>
      <ContentsWrapper>
        <div>
          <Contents>
            We are here to listen, care for you and believe a straight, healthy
            smile can be achieved at any age.
          </Contents>

          <Contents>
            We work in conjunction with our dentist colleagues, however, a
            referral is not necessary to see us.
          </Contents>
          <Contents>
            We warmly welcome you to get in contact with our friendly team.
          </Contents>
        </div>
      </ContentsWrapper>
    </Wrapper>
  );
};

export default SubTitle;
