import React from "react";
import { TeethSVG, LetterSVG, InfoSVG } from "./svg";

import {
  StyledCard,
  SvgWrapper,
  Title,
  Contents,
  StyledLink,
  ContentsWrapper,
} from "./styles";

const Card = ({ title, contents, link, svg }) => {
  return (
    <StyledCard>
      <StyledLink to={link}>
        <SvgWrapper>
          {svg === "TeethSVG" ? (
            <TeethSVG />
          ) : svg === "LetterSVG" ? (
            <LetterSVG />
          ) : (
            <InfoSVG />
          )}
        </SvgWrapper>
        <ContentsWrapper>
          <Title>{title}</Title>
          {/* <Contents>{contents}</Contents> */}
        </ContentsWrapper>
      </StyledLink>
    </StyledCard>
  );
};

export default Card;
