import styled, { css } from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import Flex from "../Flex";
import { Link } from "gatsby";

export const Container = styled(Flex)`
  justify-content: center;
  width: 100%;
  background-color: ${colors.secondary};
`;

export const Wrapper = styled(Flex)`
  max-width: ${breakpoints.xl};
  margin: 0 auto;
  padding: 2.5rem 0;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  color: ${colors.primary};
`;

export const StyledCard = styled.div`
	margin: 1.5rem 0rem;
	width: 17.5rem;
	text-align: center;
    @media (min-width: ${breakpoints.md}) {
        margin: 2rem 0rem;
        width: 15.5rem;
    }
}
`;

export const SvgWrapper = styled.div`
  svg {
    height: 3rem;
    width: 17.5rem;
    fill: ${colors.primary};
    @media (min-width: ${breakpoints.md}) {
      width: 15.5rem;
    }
  }
`;

export const Title = styled(Flex)`
  padding: 1rem 0rem 0 0rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.25rem;
  letter-spacing: 2px;
  color: ${colors.primary};
  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.375rem;
    padding-top: 1rem;
  }
`;

export const Contents = styled(Flex)`
  padding: 0.4rem 0 0 0;
  font-size: 1.125rem;
  font-family: "Red Hat Display", sans-serif;
  color: ${colors.lightBlue};
  font-weight: 300;
  margin: 0 auto;
  width: 12rem;
`;

export const ContentsWrapper = styled.div``;

export const StyledLink = styled(Link)`
	text-decoration: none;
	width: 17.5rem;
	color: ${colors.secondary};
	// letter-spacing: 1px;
	// font-size: 1rem;
	svg {
		transition: transform 0.5s ease;
		transform: scale(1);
	}

	&:hover {
		color: ${colors.secondary};

		svg {
			fill: ${colors.primaryLight};
			transform: scale(1.1);
		}
	}
    @media (min-width: ${breakpoints.md}) {
        width: 15.5rem;
    }
}
`;
