import React from "react";

export const RegistrationSVG = () => {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m378.575 0h-300.585c-13.862 0-25.138 11.277-25.138 25.139v9.57h-12.346c-13.861 0-25.138 11.277-25.138 25.139v321.316c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-321.316c0-5.591 4.548-10.139 10.138-10.139h300.585c5.59 0 10.138 4.548 10.138 10.139v427.014c0 5.591-4.548 10.139-10.138 10.139h-300.585c-5.59 0-10.138-4.548-10.138-10.139v-70.697c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v70.697c0 13.861 11.277 25.139 25.138 25.139h300.585c13.861 0 25.138-11.277 25.138-25.139v-9.57h12.345c13.861 0 25.138-11.277 25.138-25.139v-331.664c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v331.663c0 5.591-4.548 10.139-10.138 10.139h-9.571v-402.443c0-13.861-11.277-25.139-25.138-25.139-4.13 0-274.112 0-276.014 0v-9.57c.001-5.591 4.548-10.139 10.139-10.139h300.585c5.59 0 10.138 4.548 10.138 10.139v60.351c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-60.351c0-13.862-11.277-25.139-25.138-25.139z" />
        <path d="m479.717 148.796h-40.013c-9.327 0-16.915 7.588-16.915 16.915v212.903c0 .145.014.286.022.429.004.078.005.156.012.234.053.6.178 1.178.363 1.729.02.058.043.116.064.174.084.23.179.455.284.675.017.035.028.073.046.108l24.61 49.306c2.398 4.805 6.705 7.674 11.522 7.674s9.124-2.869 11.521-7.673l24.61-49.307c.022-.043.035-.089.056-.132.097-.203.184-.411.263-.624.027-.073.055-.144.079-.217.086-.257.161-.52.219-.789.004-.019.011-.037.015-.056.058-.278.097-.563.123-.852.009-.098.01-.196.015-.294.007-.128.019-.255.019-.385 0-7.78 0-202.277 0-212.903 0-9.327-7.588-16.915-16.915-16.915zm-20.006 271.97-17.295-34.651h34.591zm21.921-49.652h-43.842v-160.382h43.842zm0-175.382h-43.842v-30.021c0-1.056.859-1.915 1.915-1.915h40.013c1.056 0 1.915.859 1.915 1.915v30.021z" />
        <path d="m58.218 456.258c0 8.547 6.953 15.5 15.5 15.5h41.333c8.547 0 15.5-6.953 15.5-15.5v-39.496l17.111-18.203c2.837-3.018 2.69-7.765-.328-10.602s-7.764-2.69-10.602.328l-12.987 13.816c-2.481-1.688-5.474-2.676-8.694-2.676h-41.333c-8.547 0-15.5 6.953-15.5 15.5zm57.333-23.539v23.539c0 .275-.224.5-.5.5h-22.227c.295-.231.58-.479.841-.756zm-42.333-17.794c0-.275.224-.5.5-.5h38.443l-22.91 24.372-13.441-20.483c-.681-1.038-1.579-1.847-2.591-2.416v-.973zm0 26.782c9.497 14.472 9.039 14.02 10.351 15.051h-9.851c-.276 0-.5-.225-.5-.5z" />
        <path d="m315.625 428.091h-159.333c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h159.333c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z" />
        <path d="m75.718 242.911h132.959c9.649 0 17.5-7.851 17.5-17.5v-132.96c0-9.649-7.851-17.5-17.5-17.5h-132.959c-9.649 0-17.5 7.851-17.5 17.5v132.96c0 9.65 7.851 17.5 17.5 17.5zm-2.5-150.46c0-1.379 1.122-2.5 2.5-2.5h132.959c1.378 0 2.5 1.121 2.5 2.5v132.96c0 1.379-1.122 2.5-2.5 2.5h-132.959c-1.378 0-2.5-1.121-2.5-2.5z" />
        <path d="m113 215.89c4.14 0 7.51-3.36 7.51-7.49v-16.2c.02-11.909 9.699-21.627 21.596-21.698.031 0 .061.002.092.002.032 0 .063-.002.094-.002 11.898.07 21.578 9.788 21.598 21.698v8.699h-15.89c-4.13 0-7.5 3.36-7.5 7.5 0 4.13 3.37 7.5 7.5 7.5.01 0 .02 0 .02-.01h23.37c1.97 0 3.91-.8 5.3-2.189 1.4-1.4 2.2-3.33 2.2-5.301v-16.199c0-9.811-3.82-19.021-10.75-25.95-1.307-1.307-2.695-2.503-4.153-3.583 7.617-6.291 12.48-15.803 12.48-26.43 0-18.896-15.373-34.27-34.269-34.27s-34.269 15.373-34.269 34.27c0 10.626 4.862 20.138 12.478 26.429-1.459 1.081-2.849 2.277-4.157 3.585-6.93 6.93-10.74 16.14-10.74 25.95v16.199c0 1.971.8 3.9 2.19 5.301 1.4 1.389 3.33 2.189 5.3 2.189zm29.198-98.923c10.625 0 19.269 8.645 19.269 19.27 0 10.603-8.609 19.229-19.204 19.265-.021 0-.042-.001-.063-.001-.022 0-.044.001-.066.002-10.595-.035-19.205-8.662-19.205-19.265 0-10.627 8.644-19.271 19.269-19.271z" />
        <path d="m249.337 126.567h66.288c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-66.288c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5z" />
        <path d="m249.337 169.088h66.288c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-66.288c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5z" />
        <path d="m249.337 214.441h66.288c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-66.288c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5z" />
        <path d="m65.718 286.865h249.907c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-249.907c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5z" />
        <path d="m65.718 329.385h249.907c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-249.907c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5z" />
        <path d="m65.718 371.905h249.907c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-249.907c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5z" />
      </g>
    </svg>
  );
};

export const ContactSVG = () => {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 511.987 511.987"
      height="512"
      viewBox="0 0 511.987 511.987"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="XMLID_75_">
        <g id="XMLID_724_">
          <path
            id="XMLID_816_"
            d="m491.007 5.907c-20.045-11.575-45.767-4.681-57.338 15.364l-57.212 99.095h-123.383c-5.523 0-10 4.478-10 10s4.477 10 10 10h111.836l-41.518 71.912h-233.39c-5.523 0-10 4.478-10 10 0 5.523 4.477 10 10 10h221.842l-23.094 40h-198.748c-5.523 0-10 4.478-10 10s4.477 10 10 10h194.612l-4.309 40h-190.303c-5.523 0-10 4.478-10 10s4.477 10 10 10h188.148l-.532 4.939c-.424 3.936 1.514 7.752 4.942 9.731 1.553.897 3.278 1.34 4.999 1.34 2.079 0 4.151-.647 5.9-1.925l63.851-46.645c1.125-.822 2.065-1.869 2.761-3.075l77.929-134.975v193.827c0 22.406-18.229 40.636-40.636 40.636h-231.751c-3.573 0-6.874 1.906-8.66 5l-34.967 60.565-34.967-60.565c-1.786-3.094-5.087-5-8.66-5h-17.723c-22.407 0-40.636-18.23-40.636-40.636v-194.493c0-22.406 18.229-40.636 40.636-40.636h102.439c5.523 0 10-4.478 10-10 0-5.523-4.477-10-10-10h-102.439c-33.435 0-60.636 27.201-60.636 60.636v194.493c0 33.435 27.201 60.636 60.636 60.636h11.949l40.741 70.565c1.786 3.094 5.087 5 8.66 5s6.874-1.906 8.66-5l40.741-70.565h225.978c33.435 0 60.636-27.201 60.636-60.636v-194.493c0-8.572-1.818-17.04-5.295-24.804l53.666-92.952c11.572-20.044 4.68-45.766-15.365-57.339zm-10 17.32c10.494 6.059 14.102 19.525 8.043 30.019l-5.714 9.897-38.061-21.975 5.714-9.897c6.059-10.493 19.524-14.1 30.018-8.044zm-176.679 272.779 28.786 16.62-33.188 24.245zm43.423 1.977-38.061-21.975 125.585-217.52 38.061 21.975z"
          />
          <path
            id="XMLID_819_"
            d="m208.07 140.367c2.63 0 5.21-1.07 7.08-2.93 1.86-1.86 2.93-4.44 2.93-7.07s-1.07-5.21-2.93-7.07c-1.87-1.859-4.44-2.93-7.08-2.93-2.63 0-5.21 1.07-7.07 2.93s-2.92 4.44-2.92 7.07 1.059 5.21 2.92 7.07c1.87 1.86 4.44 2.93 7.07 2.93z"
          />
        </g>
      </g>
    </svg>
  );
};

export const LetterSVG = () => {
  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 519.571 519.571"
      height="512"
      viewBox="0 0 519.571 519.571"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path d="m515.543 157.766-52.835-39.344v-104.638c0-5.522-4.477-10-10-10h-385.846c-5.523 0-10 4.478-10 10v104.638l-52.835 39.344c-2.533 1.887-4.027 4.861-4.027 8.02v320.001c0 16.541 13.596 29.999 30.307 29.999h458.957c16.711 0 30.307-13.458 30.307-29.999v-320.001c0-3.159-1.493-6.133-4.028-8.02zm-15.972 87.936v200.171l-134.404-100.086zm0-74.894v49.958l-36.862 27.45v-104.858zm-56.863-147.024v239.324l-94.285 70.21-14.104-10.503c-6.087-4.533-14.023-7.029-22.347-7.029h-104.372c-8.325 0-16.262 2.497-22.348 7.029l-14.104 10.503-94.286-70.21v-239.324zm-385.846 119.574v104.856l-36.862-27.449v-49.957zm432.402 352.428h-458.957c-5.684 0-10.307-4.485-10.307-9.999v-14.979l51.826-38.592c4.429-3.299 5.346-9.563 2.048-13.993-3.298-4.429-9.562-5.347-13.993-2.048l-39.881 29.697v-200.171l134.405 100.085-22.336 16.633c-4.429 3.299-5.346 9.563-2.048 13.993 3.298 4.429 9.563 5.347 13.993 2.048l53.184-39.604c2.62-1.95 6.411-3.069 10.402-3.069h104.372c3.991 0 7.782 1.119 10.402 3.07l177.197 131.952v14.979c0 5.513-4.624 9.998-10.307 9.998z" />
          <path d="m216.453 133.786h13.333v13.333c0 9.189 7.477 16.666 16.667 16.666h26.667c9.19 0 16.667-7.477 16.667-16.666v-13.333h13.333c9.19 0 16.667-7.477 16.667-16.667v-26.667c0-9.19-7.477-16.667-16.667-16.667h-13.333v-13.333c0-9.189-7.477-16.666-16.667-16.666h-26.667c-9.19 0-16.667 7.477-16.667 16.666v13.333h-13.333c-9.19 0-16.667 7.477-16.667 16.667v26.667c0 9.191 7.477 16.667 16.667 16.667zm3.333-40.001h19.999c5.523 0 10-4.478 10-10v-19.999h20v19.999c0 5.522 4.477 10 10 10h20v20.001h-20c-5.523 0-10 4.478-10 10v19.999h-20v-19.999c0-5.522-4.477-10-10-10h-19.999z" />
          <path d="m136.285 215.781h247c5.523 0 10-4.478 10-10s-4.477-10-10-10h-247c-5.523 0-10 4.478-10 10s4.477 10 10 10z" />
          <path d="m136.285 255.783h247c5.523 0 10-4.478 10-10s-4.477-10-10-10h-247c-5.523 0-10 4.478-10 10s4.477 10 10 10z" />
          <path d="m348.886 275.784h-178.201c-5.523 0-10 4.478-10 10s4.477 10 10 10h178.201c5.523 0 10-4.478 10-10s-4.477-10-10-10z" />
          <path d="m101.95 387.316h-.007c-5.523 0-9.996 4.478-9.996 10s4.48 10 10.003 10 10-4.478 10-10-4.477-10-10-10z" />
        </g>
      </g>
    </svg>
  );
};

export const InfoSVG = () => {
  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 519.571 519.571"
      height="512"
      viewBox="0 0 519.571 519.571"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M3617 5105 c-149 -28 -290 -86 -409 -166 l-56 -39 -1309 -2 -1308 -3
          -63 -34 c-78 -42 -144 -114 -174 -189 l-23 -57 -3 -1859 c-1 -1228 1 -1878 8
          -1915 22 -118 92 -211 198 -263 l66 -33 202 -3 201 -3 6 -123 c8 -180 48 -270
          154 -346 106 -75 14 -71 1548 -68 l1370 3 57 27 c108 50 187 154 209 272 6 32
          8 338 7 770 l-3 718 -33 29 c-41 37 -89 39 -129 5 l-28 -24 -5 -744 -5 -743
          -23 -37 c-13 -21 -40 -45 -66 -57 -42 -21 -47 -21 -1379 -21 -1463 0 -1377 -4
          -1430 59 -36 43 -42 69 -42 181 l0 95 1095 5 1095 5 67 33 c108 53 179 150
          199 271 5 31 9 521 9 1116 l0 1060 168 1 c122 0 185 5 235 17 38 9 70 17 73
          17 2 0 4 -99 4 -219 l0 -219 26 -31 c21 -25 33 -31 66 -31 100 0 108 25 108
          337 l0 248 79 53 c150 99 291 263 366 426 242 523 27 1131 -490 1387 -139 69
          -266 100 -430 105 -88 2 -155 -1 -208 -11z m396 -214 c240 -63 444 -235 552
          -464 57 -119 77 -213 77 -357 0 -144 -20 -238 -77 -359 -197 -415 -678 -600
          -1100 -424 -141 59 -300 192 -383 321 -166 261 -178 607 -30 872 119 213 329
          369 567 421 111 24 281 20 394 -10z m-1063 -195 c0 -3 -14 -25 -30 -50 -82
          -122 -151 -311 -167 -461 l-8 -70 -865 -2 c-631 -2 -871 -6 -887 -14 -47 -25
          -53 -61 -53 -296 0 -239 7 -274 60 -300 31 -16 1843 -19 1883 -4 23 9 28 5 52
          -31 81 -122 272 -282 423 -352 l62 -30 -2 -1115 -3 -1116 -24 -38 c-12 -21
          -40 -46 -62 -57 -37 -20 -65 -20 -1384 -20 -1319 0 -1347 0 -1384 20 -22 11
          -50 36 -62 57 l-24 38 -3 1844 c-1 1222 1 1856 8 1881 13 48 41 83 82 103 30
          15 145 17 1211 17 647 0 1177 -2 1177 -4z m-202 -893 l-3 -108 -802 -3 -803
          -2 0 110 0 110 805 0 806 0 -3 -107z"
        />
        <path
          d="M3729 4591 c-78 -79 8 -205 108 -157 65 31 76 114 21 161 -42 35 -91
          34 -129 -4z"
        />
        <path
          d="M3754 4290 c-54 -21 -54 -25 -54 -433 l0 -379 29 -29 c58 -57 151
          -26 166 55 3 17 5 190 3 384 l-3 354 -33 29 c-33 30 -67 36 -108 19z"
        />
        <path
          d="M977 3115 c-34 -34 -40 -67 -23 -110 29 -68 -16 -65 993 -65 844 0
          910 1 943 18 60 30 72 108 23 157 l-26 25 -942 0 -942 0 -26 -25z"
        />
        <path
          d="M1003 2660 c-32 -13 -57 -57 -56 -98 1 -27 9 -43 32 -64 l31 -28 933
          0 c1036 0 963 -5 994 67 12 31 12 38 -4 71 -10 20 -29 42 -43 49 -19 10 -231
          13 -945 12 -507 0 -930 -4 -942 -9z"
        />
        <path
          d="M1010 2187 c-49 -17 -76 -72 -60 -122 23 -70 -46 -65 995 -65 876 0
          942 2 962 18 48 37 46 127 -4 159 -25 17 -89 18 -943 20 -692 2 -924 -1 -950
          -10z"
        />
        <path
          d="M979 1692 c-23 -21 -31 -37 -32 -64 -1 -42 24 -85 59 -98 41 -16
          1853 -13 1884 3 14 7 33 29 43 49 16 33 16 40 4 71 -31 72 42 67 -994 67
          l-933 0 -31 -28z"
        />
        <path
          d="M4126 2269 c-17 -19 -26 -43 -26 -64 0 -21 9 -45 26 -64 21 -25 33
          -31 66 -31 53 0 77 14 94 57 12 27 13 42 4 69 -15 44 -45 64 -96 64 -35 0 -47
          -5 -68 -31z"
        />
      </g>
    </svg>
  );
};

export const TeethSVG = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2922 4775 c-177 -50 -319 -192 -389 -390 -26 -76 -28 -88 -28 -265
          0 -229 7 -259 153 -675 159 -455 203 -546 291 -606 60 -39 113 -52 189 -46
          124 10 204 90 261 261 35 105 31 144 -15 165 -63 28 -105 1 -124 -81 -28 -120
          -83 -188 -150 -188 -56 0 -100 35 -136 110 -66 135 -267 716 -294 849 -23 112
          -27 288 -9 373 30 143 119 262 233 315 82 37 197 40 267 5 47 -23 119 -85 119
          -102 0 -6 -18 -10 -41 -10 -52 0 -74 -13 -88 -50 -11 -34 -3 -68 23 -89 24
          -20 258 -17 360 4 111 23 169 56 254 142 93 94 158 127 252 126 91 0 166 -32
          240 -99 74 -69 106 -123 132 -226 17 -70 20 -102 15 -213 -7 -166 -28 -251
          -150 -595 -155 -435 -182 -497 -237 -525 -64 -33 -142 -7 -173 58 -9 19 -32
          93 -52 165 -40 151 -77 231 -132 284 -45 44 -79 58 -139 58 -53 0 -84 -26 -84
          -71 0 -49 16 -66 75 -84 36 -10 60 -24 71 -40 9 -14 31 -83 49 -154 37 -152
          79 -242 139 -305 56 -57 96 -77 166 -83 147 -14 249 71 333 277 61 147 228
          630 260 750 29 107 31 127 31 295 1 178 1 181 -31 273 -58 164 -142 264 -279
          329 -216 103 -413 73 -583 -91 -86 -83 -106 -96 -151 -96 -45 0 -65 13 -161
          106 -118 115 -187 147 -319 151 -61 2 -114 -2 -148 -12z"
        />
        <path
          d="M267 2946 c-85 -31 -159 -114 -177 -199 -8 -37 -10 -301 -8 -897 l3
          -845 31 -55 c37 -66 97 -114 166 -135 75 -22 681 -22 756 0 69 21 129 69 166
          135 l31 55 3 718 3 719 132 63 c264 125 413 175 526 175 58 0 117 -17 498
          -144 467 -156 498 -170 551 -253 45 -72 55 -114 50 -212 -4 -68 -10 -97 -31
          -138 -56 -110 -137 -168 -251 -181 -81 -10 -106 -30 -106 -84 0 -76 54 -94
          189 -63 125 30 244 105 305 193 15 22 40 71 55 108 l28 68 644 89 c378 52 682
          89 736 89 87 2 97 0 160 -33 108 -55 160 -151 150 -274 -6 -75 -41 -147 -101
          -206 -24 -24 -165 -121 -313 -215 -147 -95 -522 -335 -832 -533 -408 -261
          -580 -366 -619 -377 -72 -21 -237 -15 -302 11 -25 10 -295 196 -600 414 -394
          280 -564 397 -587 399 -64 8 -108 -77 -65 -126 29 -33 1103 -793 1162 -822
          141 -69 334 -76 479 -17 87 36 1726 1089 1788 1149 93 92 143 216 143 359 0
          258 -187 429 -469 429 -51 0 -340 -34 -715 -85 -347 -47 -642 -87 -654 -88
          -22 -2 -26 5 -42 69 -38 148 -127 265 -243 322 -34 16 -249 92 -477 168 l-415
          139 -125 0 c-117 0 -131 -2 -220 -34 -52 -18 -165 -65 -250 -103 -85 -38 -161
          -72 -167 -75 -10 -4 -13 10 -13 49 0 30 -7 75 -16 101 -19 57 -83 131 -138
          160 -40 21 -51 22 -411 24 -292 2 -378 0 -408 -11z m753 -161 c63 -33 61 9 58
          -915 l-3 -840 -33 -32 -32 -33 -338 -3 -338 -3 -34 23 c-19 13 -40 35 -47 48
          -10 19 -13 211 -13 853 0 905 -2 869 58 901 41 23 679 23 722 1z"
        />
        <path
          d="M554 2594 c-58 -21 -120 -77 -151 -138 -22 -41 -27 -65 -27 -121 1
          -83 17 -126 71 -185 133 -148 381 -106 461 79 9 21 16 65 16 106 0 56 -5 80
          -27 121 -64 126 -211 185 -343 138z m157 -161 c65 -38 74 -129 19 -185 -24
          -23 -37 -28 -80 -28 -43 0 -56 5 -80 28 -98 99 21 255 141 185z"
        />
      </g>
    </svg>
  );
};
