import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { DigitalProcess } from "../components/digital-process";
import SubTitle from "../components/sub-title";
import HeroVideo from "../components/hero-video";
import LineupCards from "../components/lineup-cards";

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Gippsland Orthodontics"
        description="Gippsland Orthodontics, dentistry. providing dental services - dental implants, bridge, crowns, onlays, Inlays, Venners, Laser Dentistry, TMD and dentures"
      />
      <HeroVideo />
      <DigitalProcess />
      <SubTitle />
      {/* <Insta /> */}
      <LineupCards />
    </Layout>
  );
};

export default IndexPage;
