import styled from "styled-components";
import Img from "gatsby-image";
import { Button } from "@material-ui/core";
import { breakpoints } from "../../styles/variables";
import { colors } from "../../styles/variables";
import Modal from "styled-react-modal";
import { FaPlay } from "react-icons/fa";

export const Wrapper = styled.div`
  position: relative;
`;

export const SliderWrapper = styled.div`
  position: relative;
`;
export const StyledImg = styled(Img)`
  min-height: 600px;
  min-width: 1200px;
  transition: all 0.5s ease-in-out;
  position: relative;
  transform: scale(1.1);
  filter: grayscale(60%);
  left: -430px;
  @media (min-width: ${breakpoints.sm}) {
    left: -300px;
  }
  @media (min-width: ${breakpoints.md}) {
    left: -150px;
  }
  @media (min-width: ${breakpoints.lg}) {
    left: 0px;
  }
`;

export const StyledIcon = styled(FaPlay)`
  font-size: 1.7rem;
  color: #fff;
`;

export const StyledModal = Modal.styled`
    width: 80vw;
    height: 80vh;
    transition: opacity ease 500ms;
`;

export const SlideWrapper = styled.div`
  position: relative;
`;

export const ButtonWrapper = styled.div`
	width: 100%;
    margin 
`;

export const TitleContainer = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 auto;
  padding: 3rem 1rem 3rem 1rem;
  max-width: 1200px;
  text-align: center;
  letter-spacing: 1.2px;
  background-color: transparent;
  color: ${colors.light};
  // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 20%;
  left: 5%;
  bottom: unset;
  transform: translate(0, 0);
  @media (min-width: ${breakpoints.sm}) {
    font-size: 2rem;
    color: ${colors.primary};
  }
`;
export const Title = styled.h1`
  color: ${colors.light};
  margin: 0 auto;
  font-size: 1.75rem;
  font-weight: 700;
  text-align: left;
  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.75rem;
    color: ${colors.gray};
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 2rem;
    max-width: 30rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 2.5rem;
    max-width: 50rem;
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 80%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: orange;
    }
  }
`;
export const Details = styled.p`
  margin-top: 3.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  width: 250px;
  @media (min-width: 380px) {
    margin-top: 4.5rem;
  }
  @media (min-width: ${breakpoints.sm}) {
    margin-top: 3rem;
    width: 450px;
  }
`;

export const StyledButton = styled(Button)`
  background-color: transparent;
  color: ${colors.primary};
  border: 1px solid ${colors.primary};
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: ${colors.light};
    color: ${colors.secondary};
  }
`;

export const SecondTitleContainer = styled.div`
  margin: 0 auto;
  padding: 3rem 1rem 3rem 1rem;
  text-align: center;
  letter-spacing: 1.2px;
  background-color: ${colors.secondary};
  color: ${colors.light};

  @media (min-width: ${breakpoints.sm}) {
    font-size: 2rem;
    color: ${colors.primary};
  }
`;
export const SecondTitle = styled.h2`
  color: ${colors.light};
  margin: 0 auto;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  // overflow: hidden; /* Ensures the content is not revealed until the animation */
  // border-right: 0.15em solid orange; /* The typwriter cursor */
  // white-space: nowrap; /* Keeps the content on a single line */
  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.75rem;
    color: ${colors.gray};
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 1.5rem;
    max-width: 30rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 1.75rem;
    max-width: 60rem;
  }
  // animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end 0.75s;
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 80%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: orange;
    }
  }
`;
